(function() {
    'use strict';

    angular
        .module('teJHipsterApp')
        .controller('TaskObjectTypeDetailController', TaskObjectTypeDetailController);

    TaskObjectTypeDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'TaskObjectType'];

    function TaskObjectTypeDetailController($scope, $rootScope, $stateParams, previousState, entity, TaskObjectType) {
        var vm = this;

        vm.taskObjectType = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('teJHipsterApp:taskObjectTypeUpdate', function(event, result) {
            vm.taskObjectType = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
