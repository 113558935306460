(function() {
    'use strict';

    angular
        .module('teJHipsterApp')
        .factory('ProfessionalAreaGroupSearch', ProfessionalAreaGroupSearch);

    ProfessionalAreaGroupSearch.$inject = ['$resource'];

    function ProfessionalAreaGroupSearch($resource) {
        var resourceUrl =  'api/_search/professional-area-groups/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true}
        });
    }
})();
