(function() {
    'use strict';

    angular
        .module('teJHipsterApp')
        .factory('FrequentlyAskedQuestionSearch', FrequentlyAskedQuestionSearch);

    FrequentlyAskedQuestionSearch.$inject = ['$resource'];

    function FrequentlyAskedQuestionSearch($resource) {
        var resourceUrl =  'api/_search/frequently-asked-questions/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true}
        });
    }
})();
