(function() {
    'use strict';
    angular
        .module('teJHipsterApp')
        .factory('SpecificQuestion', SpecificQuestion);

    SpecificQuestion.$inject = ['$resource', 'DateUtils'];

    function SpecificQuestion ($resource, DateUtils) {
        var resourceUrl =  'api/specific-questions/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.creationtime = DateUtils.convertDateTimeFromServer(data.creationtime);
                        data.answertime = DateUtils.convertDateTimeFromServer(data.answertime);
                    }
                    return data;
                }
            },
            'update': { method:'PUT' }
        });
    }
})();
