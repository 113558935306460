(function() {
    'use strict';

    angular
        .module('teJHipsterApp')
        .controller('HandbookVersionDialogController', HandbookVersionDialogController);

    HandbookVersionDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'HandbookVersion', 'Handbook', 'CustomUser', 'HandbookNode'];

    function HandbookVersionDialogController ($timeout, $scope, $stateParams, $uibModalInstance, entity, HandbookVersion, Handbook, CustomUser, HandbookNode) {
        var vm = this;

        vm.handbookVersion = entity;
        vm.clear = clear;
        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
        vm.save = save;
        vm.handbooks = Handbook.query();
        vm.customusers = CustomUser.query();
        vm.handbooknodes = HandbookNode.query();
        vm.handbookversions = HandbookVersion.query();

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            if (vm.handbookVersion.id !== null) {
                HandbookVersion.update(vm.handbookVersion, onSaveSuccess, onSaveError);
            } else {
                HandbookVersion.save(vm.handbookVersion, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('teJHipsterApp:handbookVersionUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }

        vm.datePickerOpenStatus.validFrom = false;
        vm.datePickerOpenStatus.validUntil = false;
        vm.datePickerOpenStatus.publicationDate = false;
        vm.datePickerOpenStatus.creationTime = false;
        vm.datePickerOpenStatus.revisionTime = false;

        function openCalendar (date) {
            vm.datePickerOpenStatus[date] = true;
        }
    }
})();
