(function() {
    'use strict';

    angular
        .module('teJHipsterApp')
        .controller('ProfessionalAreaGroupDetailController', ProfessionalAreaGroupDetailController);

    ProfessionalAreaGroupDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'ProfessionalAreaGroup', 'ProfessionalArea'];

    function ProfessionalAreaGroupDetailController($scope, $rootScope, $stateParams, previousState, entity, ProfessionalAreaGroup, ProfessionalArea) {
        var vm = this;

        vm.professionalAreaGroup = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('teJHipsterApp:professionalAreaGroupUpdate', function(event, result) {
            vm.professionalAreaGroup = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
