(function() {
    'use strict';

    angular
        .module('teJHipsterApp')
        .controller('HandbookNodeDialogController', HandbookNodeDialogController);

    HandbookNodeDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', '$q', 'entity', 'HandbookNode', 'HandbookVersion', 'File'];

    function HandbookNodeDialogController ($timeout, $scope, $stateParams, $uibModalInstance, $q, entity, HandbookNode, HandbookVersion, File) {
        var vm = this;

        vm.handbookNode = entity;
        vm.clear = clear;
        vm.save = save;
        vm.handbookversions = HandbookVersion.query();
        vm.handbooknodes = HandbookNode.query();
        vm.files = File.query();
        vm.previoussiblingnodes = HandbookNode.query({filter: 'nextsiblingnode-is-null'});
        $q.all([vm.handbookNode.$promise, vm.previoussiblingnodes.$promise]).then(function() {
            if (!vm.handbookNode.previousSiblingNodeId) {
                return $q.reject();
            }
            return HandbookNode.get({id : vm.handbookNode.previousSiblingNodeId}).$promise;
        }).then(function(previousSiblingNode) {
            vm.previoussiblingnodes.push(previousSiblingNode);
        });

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            if (vm.handbookNode.id !== null) {
                HandbookNode.update(vm.handbookNode, onSaveSuccess, onSaveError);
            } else {
                HandbookNode.save(vm.handbookNode, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('teJHipsterApp:handbookNodeUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }


    }
})();
