(function() {
    'use strict';

    angular
        .module('teJHipsterApp')
        .controller('CustomActivityDeleteController',CustomActivityDeleteController);

    CustomActivityDeleteController.$inject = ['$uibModalInstance', 'entity', 'CustomActivity'];

    function CustomActivityDeleteController($uibModalInstance, entity, CustomActivity) {
        var vm = this;

        vm.customActivity = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete (id) {
            CustomActivity.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        }
    }
})();
