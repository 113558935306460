(function() {
    'use strict';

    angular
        .module('teJHipsterApp')
        .controller('HandbookFolderDialogController', HandbookFolderDialogController);

    HandbookFolderDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'HandbookFolder', 'Handbook', 'CustomUser'];

    function HandbookFolderDialogController ($timeout, $scope, $stateParams, $uibModalInstance, entity, HandbookFolder, Handbook, CustomUser) {
        var vm = this;

        vm.handbookFolder = entity;
        vm.clear = clear;
        vm.save = save;
        vm.handbooks = Handbook.query();
        vm.customusers = CustomUser.query();

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            if (vm.handbookFolder.id !== null) {
                HandbookFolder.update(vm.handbookFolder, onSaveSuccess, onSaveError);
            } else {
                HandbookFolder.save(vm.handbookFolder, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('teJHipsterApp:handbookFolderUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }


    }
})();
