(function() {
    'use strict';

    angular
        .module('teJHipsterApp')
        .factory('HandbookStatisticSearch', HandbookStatisticSearch);

    HandbookStatisticSearch.$inject = ['$resource'];

    function HandbookStatisticSearch($resource) {
        var resourceUrl =  'api/_search/handbook-statistics/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true}
        });
    }
})();
