(function() {
    'use strict';

    angular
        .module('teJHipsterApp')
        .controller('CustomActivityDialogController', CustomActivityDialogController);

    CustomActivityDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'CustomActivity'];

    function CustomActivityDialogController ($timeout, $scope, $stateParams, $uibModalInstance, entity, CustomActivity) {
        var vm = this;

        vm.customActivity = entity;
        vm.clear = clear;
        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
        vm.save = save;

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            if (vm.customActivity.id !== null) {
                CustomActivity.update(vm.customActivity, onSaveSuccess, onSaveError);
            } else {
                CustomActivity.save(vm.customActivity, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('teJHipsterApp:customActivityUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }

        vm.datePickerOpenStatus.creationTime = false;
        vm.datePickerOpenStatus.validUntil = false;

        function openCalendar (date) {
            vm.datePickerOpenStatus[date] = true;
        }
    }
})();
