(function() {
    'use strict';

    angular
        .module('teJHipsterApp')
        .controller('ProfessionalAreaDialogController', ProfessionalAreaDialogController);

    ProfessionalAreaDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'ProfessionalArea', 'CustomUser', 'ProfessionalAreaGroup', 'Order'];

    function ProfessionalAreaDialogController ($timeout, $scope, $stateParams, $uibModalInstance, entity, ProfessionalArea, CustomUser, ProfessionalAreaGroup, Order) {
        var vm = this;

        vm.professionalArea = entity;
        vm.clear = clear;
        vm.save = save;
        vm.customusers = CustomUser.query();
        vm.professionalareagroups = ProfessionalAreaGroup.query();
        vm.orders = Order.query();

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            if (vm.professionalArea.id !== null) {
                ProfessionalArea.update(vm.professionalArea, onSaveSuccess, onSaveError);
            } else {
                ProfessionalArea.save(vm.professionalArea, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('teJHipsterApp:professionalAreaUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }


    }
})();
