(function() {
    'use strict';

    angular
        .module('teJHipsterApp')
        .controller('FileContentDetailController', FileContentDetailController);

    FileContentDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'DataUtils', 'entity', 'FileContent'];

    function FileContentDetailController($scope, $rootScope, $stateParams, previousState, DataUtils, entity, FileContent) {
        var vm = this;

        vm.fileContent = entity;
        vm.previousState = previousState.name;
        vm.byteSize = DataUtils.byteSize;
        vm.openFile = DataUtils.openFile;

        var unsubscribe = $rootScope.$on('teJHipsterApp:fileContentUpdate', function(event, result) {
            vm.fileContent = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
