(function() {
    'use strict';

    angular
        .module('teJHipsterApp')
        .controller('HandbookVersionStateHistoryDeleteController',HandbookVersionStateHistoryDeleteController);

    HandbookVersionStateHistoryDeleteController.$inject = ['$uibModalInstance', 'entity', 'HandbookVersionStateHistory'];

    function HandbookVersionStateHistoryDeleteController($uibModalInstance, entity, HandbookVersionStateHistory) {
        var vm = this;

        vm.handbookVersionStateHistory = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete (id) {
            HandbookVersionStateHistory.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        }
    }
})();
