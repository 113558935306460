(function() {
    'use strict';

    angular
        .module('teJHipsterApp')
        .controller('ProfessionalAreaGroupDialogController', ProfessionalAreaGroupDialogController);

    ProfessionalAreaGroupDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'ProfessionalAreaGroup', 'ProfessionalArea'];

    function ProfessionalAreaGroupDialogController ($timeout, $scope, $stateParams, $uibModalInstance, entity, ProfessionalAreaGroup, ProfessionalArea) {
        var vm = this;

        vm.professionalAreaGroup = entity;
        vm.clear = clear;
        vm.save = save;
        vm.professionalareas = ProfessionalArea.query();

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            if (vm.professionalAreaGroup.id !== null) {
                ProfessionalAreaGroup.update(vm.professionalAreaGroup, onSaveSuccess, onSaveError);
            } else {
                ProfessionalAreaGroup.save(vm.professionalAreaGroup, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('teJHipsterApp:professionalAreaGroupUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }


    }
})();
