(function() {
    'use strict';

    angular
        .module('teJHipsterApp')
        .controller('SpecificQuestionDetailController', SpecificQuestionDetailController);

    SpecificQuestionDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'SpecificQuestion', 'Answer', 'Comment', 'Task'];

    function SpecificQuestionDetailController($scope, $rootScope, $stateParams, previousState, entity, SpecificQuestion, Answer, Comment, Task) {
        var vm = this;

        vm.specificQuestion = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('teJHipsterApp:specificQuestionUpdate', function(event, result) {
            vm.specificQuestion = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
