(function() {
    'use strict';

    angular
        .module('teJHipsterApp')
        .controller('HandbookMergeHeaderDialogController', HandbookMergeHeaderDialogController);

    HandbookMergeHeaderDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'HandbookMergeHeader', 'Handbook', 'HandbookVersion', 'CustomUser'];

    function HandbookMergeHeaderDialogController ($timeout, $scope, $stateParams, $uibModalInstance, entity, HandbookMergeHeader, Handbook, HandbookVersion, CustomUser) {
        var vm = this;

        vm.handbookMergeHeader = entity;
        vm.clear = clear;
        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
        vm.save = save;
        vm.handbooks = Handbook.query();
        vm.handbookversions = HandbookVersion.query();
        vm.customusers = CustomUser.query();

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            if (vm.handbookMergeHeader.id !== null) {
                HandbookMergeHeader.update(vm.handbookMergeHeader, onSaveSuccess, onSaveError);
            } else {
                HandbookMergeHeader.save(vm.handbookMergeHeader, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('teJHipsterApp:handbookMergeHeaderUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }

        vm.datePickerOpenStatus.startTime = false;
        vm.datePickerOpenStatus.finishTime = false;

        function openCalendar (date) {
            vm.datePickerOpenStatus[date] = true;
        }
    }
})();
