(function() {
    'use strict';

    angular
        .module('teJHipsterApp')
        .controller('TaskDialogController', TaskDialogController);

    TaskDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'Task', 'CustomUser', 'SpecificQuestion', 'Answer', 'FrequentlyAskedQuestion', 'HandbookVersion'];

    function TaskDialogController ($timeout, $scope, $stateParams, $uibModalInstance, entity, Task, CustomUser, SpecificQuestion, Answer, FrequentlyAskedQuestion, HandbookVersion) {
        var vm = this;

        vm.task = entity;
        vm.clear = clear;
        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
        vm.save = save;
        vm.customusers = CustomUser.query();
        vm.specificquestions = SpecificQuestion.query();
        vm.answers = Answer.query();
        vm.frequentlyaskedquestions = FrequentlyAskedQuestion.query();
        vm.handbookversions = HandbookVersion.query();

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            if (vm.task.id !== null) {
                Task.update(vm.task, onSaveSuccess, onSaveError);
            } else {
                Task.save(vm.task, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('teJHipsterApp:taskUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }

        vm.datePickerOpenStatus.assignmenttime = false;
        vm.datePickerOpenStatus.completiontime = false;

        function openCalendar (date) {
            vm.datePickerOpenStatus[date] = true;
        }
    }
})();
