(function() {
    'use strict';

    angular
        .module('teJHipsterApp')
        .controller('HandbookFolderDeleteController',HandbookFolderDeleteController);

    HandbookFolderDeleteController.$inject = ['$uibModalInstance', 'entity', 'HandbookFolder'];

    function HandbookFolderDeleteController($uibModalInstance, entity, HandbookFolder) {
        var vm = this;

        vm.handbookFolder = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete (id) {
            HandbookFolder.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        }
    }
})();
