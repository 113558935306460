(function() {
    'use strict';

    angular
        .module('teJHipsterApp')
        .factory('SpecificQuestionSearch', SpecificQuestionSearch);

    SpecificQuestionSearch.$inject = ['$resource'];

    function SpecificQuestionSearch($resource) {
        var resourceUrl =  'api/_search/specific-questions/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true}
        });
    }
})();
