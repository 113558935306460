(function() {
    'use strict';

    angular
        .module('teJHipsterApp')
        .controller('CustomUserDialogController', CustomUserDialogController);

    CustomUserDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', '$q', 'entity', 'CustomUser', 'User', 'ProfessionalArea', 'Answer', 'Comment', 'Task', 'FrequentlyAskedQuestion'];

    function CustomUserDialogController ($timeout, $scope, $stateParams, $uibModalInstance, $q, entity, CustomUser, User, ProfessionalArea, Answer, Comment, Task, FrequentlyAskedQuestion) {
        var vm = this;

        vm.customUser = entity;
        vm.clear = clear;
        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
        vm.save = save;
        vm.users = User.query();
        vm.professionalareas = ProfessionalArea.query();
        vm.answers = Answer.query();
        vm.comments = Comment.query();
        vm.tasks = Task.query();
        vm.frequentlyaskedquestions = FrequentlyAskedQuestion.query();

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            if (vm.customUser.id !== null) {
                CustomUser.update(vm.customUser, onSaveSuccess, onSaveError);
            } else {
                CustomUser.save(vm.customUser, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('teJHipsterApp:customUserUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }

        vm.datePickerOpenStatus.lastLogin = false;
        vm.datePickerOpenStatus.processingOfPersonalDataContractAgreement = false;

        function openCalendar (date) {
            vm.datePickerOpenStatus[date] = true;
        }
    }
})();
