(function() {
    'use strict';

    angular
        .module('teJHipsterApp')
        .factory('HandbookMergeHeaderSearch', HandbookMergeHeaderSearch);

    HandbookMergeHeaderSearch.$inject = ['$resource'];

    function HandbookMergeHeaderSearch($resource) {
        var resourceUrl =  'api/_search/handbook-merge-headers/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true}
        });
    }
})();
