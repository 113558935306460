(function() {
    'use strict';

    angular
        .module('teJHipsterApp')
        .factory('FileContentSearch', FileContentSearch);

    FileContentSearch.$inject = ['$resource'];

    function FileContentSearch($resource) {
        var resourceUrl =  'api/_search/file-contents/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true}
        });
    }
})();
