(function() {
    'use strict';

    angular
        .module('teJHipsterApp')
        .factory('FrequentlyAskedQuestionStateHistorySearch', FrequentlyAskedQuestionStateHistorySearch);

    FrequentlyAskedQuestionStateHistorySearch.$inject = ['$resource'];

    function FrequentlyAskedQuestionStateHistorySearch($resource) {
        var resourceUrl =  'api/_search/frequently-asked-question-state-histories/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true}
        });
    }
})();
