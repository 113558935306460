(function() {
    'use strict';

    angular
        .module('teJHipsterApp')
        .controller('UserActivationDialogController', UserActivationDialogController);

    UserActivationDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', '$q', 'entity', 'UserActivation', 'CustomUser'];

    function UserActivationDialogController ($timeout, $scope, $stateParams, $uibModalInstance, $q, entity, UserActivation, CustomUser) {
        var vm = this;

        vm.userActivation = entity;
        vm.clear = clear;
        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
        vm.save = save;
        vm.customusers = CustomUser.query({filter: 'useractivation-is-null'});
        $q.all([vm.userActivation.$promise, vm.customusers.$promise]).then(function() {
            if (!vm.userActivation.customUserId) {
                return $q.reject();
            }
            return CustomUser.get({id : vm.userActivation.customUserId}).$promise;
        }).then(function(customUser) {
            vm.customusers.push(customUser);
        });

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            if (vm.userActivation.id !== null) {
                UserActivation.update(vm.userActivation, onSaveSuccess, onSaveError);
            } else {
                UserActivation.save(vm.userActivation, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('teJHipsterApp:userActivationUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }

        vm.datePickerOpenStatus.lastSyncTime = false;

        function openCalendar (date) {
            vm.datePickerOpenStatus[date] = true;
        }
    }
})();
