(function() {
    'use strict';

    angular
        .module('teJHipsterApp')
        .controller('CustomActivityDetailController', CustomActivityDetailController);

    CustomActivityDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'CustomActivity'];

    function CustomActivityDetailController($scope, $rootScope, $stateParams, previousState, entity, CustomActivity) {
        var vm = this;

        vm.customActivity = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('teJHipsterApp:customActivityUpdate', function(event, result) {
            vm.customActivity = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
