(function() {
    'use strict';

    angular
        .module('teJHipsterApp')
        .controller('TaskObjectTypeDeleteController',TaskObjectTypeDeleteController);

    TaskObjectTypeDeleteController.$inject = ['$uibModalInstance', 'entity', 'TaskObjectType'];

    function TaskObjectTypeDeleteController($uibModalInstance, entity, TaskObjectType) {
        var vm = this;

        vm.taskObjectType = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete (id) {
            TaskObjectType.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        }
    }
})();
