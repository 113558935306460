(function() {
    'use strict';

    angular
        .module('teJHipsterApp')
        .controller('SpecificQuestionDialogController', SpecificQuestionDialogController);

    SpecificQuestionDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', '$q', 'entity', 'SpecificQuestion', 'Answer', 'Comment', 'Task'];

    function SpecificQuestionDialogController ($timeout, $scope, $stateParams, $uibModalInstance, $q, entity, SpecificQuestion, Answer, Comment, Task) {
        var vm = this;

        vm.specificQuestion = entity;
        vm.clear = clear;
        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
        vm.save = save;
        vm.selectedanswers = Answer.query({filter: 'selectedforspecificquestion-is-null'});
        $q.all([vm.specificQuestion.$promise, vm.selectedanswers.$promise]).then(function() {
            if (!vm.specificQuestion.selectedAnswerId) {
                return $q.reject();
            }
            return Answer.get({id : vm.specificQuestion.selectedAnswerId}).$promise;
        }).then(function(selectedAnswer) {
            vm.selectedanswers.push(selectedAnswer);
        });
        vm.comments = Comment.query();
        vm.tasks = Task.query();
        vm.answers = Answer.query();

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            if (vm.specificQuestion.id !== null) {
                SpecificQuestion.update(vm.specificQuestion, onSaveSuccess, onSaveError);
            } else {
                SpecificQuestion.save(vm.specificQuestion, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('teJHipsterApp:specificQuestionUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }

        vm.datePickerOpenStatus.creationtime = false;
        vm.datePickerOpenStatus.answertime = false;

        function openCalendar (date) {
            vm.datePickerOpenStatus[date] = true;
        }
    }
})();
